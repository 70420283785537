<script>
export default {
	name: 'StepBar',
	props: {
		step: {
			default: 0,
			type: Number,
		},
	},
	data() {
		return {
			list: [
				'購物車',
				// '登入會員',
				'訂單確認',
				'完成',
			],
		}
	},
	render() {
		return (
			<div class="step-bar">
				<div className="step-bar_line"></div>
				{this.list.map((item, index) => {
					return (
						<div
							class={{
								'step-bar_option': true,
								'before-active': index < this.step,
							}}
						>
							<div
								class={{
									'step-bar_option_circle': true,
									'before-active': index < this.step,
									active: index === this.step,
								}}
							></div>
							<div
								class={{
									'step-bar_option_name': true,
									active: index === this.step,
								}}
							>
								{item}
							</div>
						</div>
					)
				})}
			</div>
		)
	},
}
</script>

<style lang="sass" scoped>
.step-bar
  position: relative
  padding: 20px 0
  text-align: center
  display: flex
  flex-wrap: wrap
  font-size: 16px
  .normal
    color: #6a6a6a
  .highlight
    color: #000
    font-weight: bold
  &_line
    background: #DDDDDD
  &_option
    flex: 1
    display: flex
    flex-direction: column
    align-items: center
    position: relative
    z-index: 1
    &:not(:last-child)
      &::before
        content: ''
        position: absolute
        left: 50%
        top: 5px
        width: 100%
        height: 6px
        background: #DDDDDD
        z-index: -1
    &.before-active
      &::before
        background: #294B70
    &_circle
      width: 16px
      height: 16px
      background: #DDDDDD
      border-radius: 50%
      border: 4px solid #DDDDDD
      &.before-active
        border-color: #294B70
        background: #fff
      &.active
        border-color: #294B70
        background: #294B70
    &_name
      margin-top: 12px
      &.active
        font-weight: 900
</style>
